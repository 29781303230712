import styled from "styled-components";
import css from "@styled-system/css";

export const InlineLink = styled.a`
  text-decoration: none;
  cursor: pointer;

  ${css({
    color: "foreground.link",
    fontWeight: "bold",
  })}
`;
