import React from "react";
import { InlineLink } from "../components/link";
import { Content } from "../components/content";
import { Grid } from "../components/grid";
import { H1, H2, Text } from "../components/text";
import { Hero } from "../components/hero";
import { Layout } from "../components/layout";
import { Seo } from "../components/seo";
import { Spacer } from "../components/spacer";
import { ExternalLinkButton } from "../components/button";
import HeroImage from "../images/pages/about-us/hero.jpg";
import SEOImage from "../images/wvs-india-seo.jpg";

const Index = () => {
  return (
    <Layout>
      <Seo lang="en" title="Contact" image={SEOImage} />
      <Hero image={HeroImage}>
        <H1>Donate</H1>
      </Hero>
      <Content>
        <H2>Foreign contributions account</H2>
        <Text>
          Worldwide Veterinary Service India <br />
          Account number: 40087129129
          <br />
          IFS Code: SBIN0000691
          <br />
          SWIFT Code: SBININBB104
          <br />
          Address: FCRA Cell, 4th Floor, State Bank of India, New Delhi Main
          Branch -11, Sansad Marg, New Delhi 110001
        </Text>
        <Spacer height={3} width={3} />
        <H2>For domestic contributions</H2>
        <Spacer height={3} width={3} />
        <Grid columns={2}>
          <div>
            <Text>
              WVS India ITC <br />
              Worldwide Veterinary Service India
              <br />
              Account number: 910010042193677
              <br />
              IFS Code: UTIB0000182
              <br />
              Ootacamund
              <br />
            </Text>
            <Text>
              For{" "}
              <InlineLink to="/courses" title="programme donations">
                programme donations
              </InlineLink>{" "}
              we prefer Demand Draft sent by post as a mode of
              payment. For more details regarding registration on the our programmes, please contact the centre (Ooty or Goa) that you
              are applying for.
            </Text>
          </div>
          <div
            style={{
              alignItems: "center",
              display: "flex",
              flexDirection: "column",
              width: "100%",
            }}
          >
            <Text>
              If you wish to donate towards our All Dogs Matter sterilization
              campaign in South Goa, taking place from September 2023, please
              make your donation through the button below. <br />
            </Text>
            <Text style={{ marginBottom: "20px" }}>
              Please note that all donations made are considered as contribution
              towards this project.
            </Text>
            <ExternalLinkButton
              title="Donate"
              href="https://wvshicks.mojo.page/all-dogs-matter-camp-goa"
            >
              Donate to Goa ABC Camp
            </ExternalLinkButton>
          </div>
        </Grid>
      </Content>
    </Layout>
  );
};

export default Index;
